import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { useDispatch } from 'react-redux';
import { isClient, isAddonInstance, SIGNING_AUDIT_TRAIL_TYPE } from '../../helpers/utils';
import styles from './order-summary.module.scss';
import { setLoader } from '../../store/actions';
import getSignedAgreement from '../../services/api/actions/getSignedAgreement';

const OrderSummary = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const usernameTxt = intl.formatMessage({ id: 'order-summary.username' });
  const orderTxt = intl.formatMessage({ id: 'order-summary.order' });
  const dateTxt = intl.formatMessage({ id: 'order-summary.date' });
  const addressTxt = intl.formatMessage({ id: 'order-summary.address' });
  const downloadAgreementTxt = intl.formatMessage({
    id: 'order-summary.download_agreement_details',
  });

  const info = isClient ? JSON.parse(localStorage.getItem('boughtProducts')) || {} : {};

  const {
    userName,
    order,
    date,
    paymentMethod,
    address,
    subtotal,
    fromEWalletAmount,
    amountFee,
    amountVat,
  } = info || {};

  const details = [
    {
      label: usernameTxt,
      value: userName,
    },
    {
      label: dateTxt,
      value: date,
    },
    {
      label: addressTxt,
      value: address,
    },
  ];

  const [auditTrialId, setAuditTrialId] = useState('');
  useEffect(() => {
    if (isClient && SIGNING_AUDIT_TRAIL_TYPE.SIGNING_COMPLETED) {
      const id = localStorage.getItem('audit-trial-id');
      if (id) {
        setAuditTrialId(id);
      }
    }
  }, []);

  const downloadAgreementFiles = async (e) => {
    e.preventDefault();
    if (!isClient) {
      return;
    }
    dispatch(setLoader(true));
    const res = await dispatch(getSignedAgreement.withQuery(`${auditTrialId}`).action());
    dispatch(setLoader(false));
    if (res.payload.data?.data?.url) {
      if (isClient) {
        const url = await fetch(res.payload.data?.data?.url)
          .then((r) => r.blob())
          .then((blob) => window.URL.createObjectURL(blob));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ibi_agreement_and_related_policies.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    }
  };

  return (
    <div className={styles.order}>
      <div>
        {details.map(({ label, value }) => (
          <DetailsRow key={label} label={label} value={value} />
        ))}
      </div>
      <div className={styles.divider} />
      {auditTrialId && (
        <div>
          <div className={styles.action}>
            <span>
              <i className="icon-download" />
              <a href="/" onClick={(e) => downloadAgreementFiles(e)}>
                {downloadAgreementTxt}
              </a>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;

function DetailsRow({ label, value }) {
  return (
    <div className={styles.detailsRow}>
      <div className={styles.detailsLeftCol}>{label}</div>
      <div className={styles.detailsRightCol}>{value}</div>
    </div>
  );
}

DetailsRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function ProductRow({ name, price }) {
  return (
    <div className={styles.productRow}>
      <div className={styles.productLeftCol}>{name}</div>
      <div className={styles.productRightCol}>
        <div className={styles.productPrice}>{`${price}$`}</div>
        <div className={styles.link} />
      </div>
    </div>
  );
}

ProductRow.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};
